const CardStoreComponent = ({ uniqueKey, name, url_link, img_link }) => {
  const urlSplitted = url_link.split("https://");
  if (urlSplitted.length > 1) {
    url_link = urlSplitted[1];
  }

  return (
    <div className="card bg-slate-100 shadow-xl">
      <a key={uniqueKey} href={`https://${url_link}`} target="_blank">
        <div className="rounded-xl">
          <img src={img_link} alt={name} width={600} height={600} />
        </div>
        <div className="flex flex-row items-center justify-center p-3">
          <h2 className="card-title text-2xl max-md:text-lg text-black text-center">
            {name}
          </h2>
        </div>
      </a>
    </div>
  );
};

export default CardStoreComponent;
