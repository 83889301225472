"use client";
import CarouselComponent from "../../components/CarouselComponent";
import CardStoreComponent from "../../components/CardStoreComponent";
import NavbarBlackComponent from "../../components/NavbarBlackComponent";
import HFullContainer from "../../components/HFullContainer";
import datas from "../../datas/marketplace.json";
import axios from "axios";
import { useEffect, useState } from "react";

export default function Store() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [bannerDatas, setBannerDatas] = useState([]);
  const [marketplaceDatas, setMarketplaceDatas] = useState([]);

  const getBannerDatas = async () => {
    try {
      const response = await axios.get(
        "https://api.onarstreet.co.id/api/banner-promo"
      );
      const datas = await response.data.data;
      setBannerDatas(datas);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  const getMarketplceDatas = async () => {
    try {
      const response = await axios.get(
        "https://api.onarstreet.co.id/api/store-index"
      );
      const datas = await response.data.data;

      setMarketplaceDatas(datas);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBannerDatas();
    getMarketplceDatas();
  }, []);

  return (
    <>
      <NavbarBlackComponent />
      <div className="flex lg:flex-row md:flex-col sm:flex-col flex-col bg-white">
        <div className="lg:w-1/2 md:w-full sm:w-full h-full flex flex-row items-center justify-center pt-12 px-4 lg:p-20 lg:mt-12 md:px-20 md:mt-24 mt-12">
          <img
            src="/assets/logo/logo_black.jpg"
            className="h-full w-full rounded-lg"
          />
        </div>
        <div className="lg:w-1/2 md:w-full sm:w-full lg:p-8 lg:mt-24 md:mt-0 md:px-20 pt-8 px-4">
          <div className="text-center grid grid-cols-2 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 gap-2">
            {/* <CarouselComponent data={bannerDatas} isLoaded={isLoaded} /> */}
            {bannerDatas.map((data, key) => {
              if (isLoaded === true && key >= bannerDatas.length - 4) {
                return (
                  <a href={`${data.link}`} target="_blank">
                    <img
                      src={data.file_gambar}
                      alt={`Image Banner ${key}`}
                      className="object-contain h-full w-full"
                    />
                  </a>
                );
              }
            })}
          </div>
          <div className="text-black font-bold lg:text-2xl md:text-2xl sm:text-xl lg:my-8 md:my-8 sm:my-8 my-4 text-center underline underline-offset-2 uppercase">
            Official Store Kami
          </div>
          <div className="mx-auto grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-8 md:pb-20">
            {marketplaceDatas.map((data, key) => {
              return (
                <CardStoreComponent
                  key={key}
                  uniqueKey={key}
                  name={data.nama}
                  url_link={data.link}
                  img_link={data.file_gambar}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
