import React, { useState, useEffect, useRef } from "react";

const CarouselComponent = ({ data, isLoaded }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    let interval;

    if (data.length > 0 && isLoaded) {
      const changeImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
      };

      interval = setInterval(changeImage, 5000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [data, isLoaded]);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;

    const currentX = e.touches[0].clientX;
    const diffX = currentX - startX;

    if (Math.abs(diffX) < 50) return; // Toleransi pergerakan minimal

    if (diffX > 0) {
      // Pergeseran ke kanan
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + data.length) % data.length
      );
    } else {
      // Pergeseran ke kiri
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }

    setIsDragging(false);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  return (
    <>
      <div className="card w-full h-full shadow-xl">
        <div
          className="carousel"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          ref={carouselRef}
        >
          {data.length === 0 && isLoaded === false
            ? "Data sedang dimuat"
            : data.map((item, index) => (
                <div
                  key={index}
                  id={`item${index}`}
                  className={`carousel-item w-full bg-slate-100 rounded-xl ${
                    currentIndex === index ? "visible" : "hidden"
                  }`}
                >
                  <a href={item.link} className="w-full h-full">
                    <div className="card w-full">
                      <img
                        src={item.file_gambar}
                        alt={`Carousel Image ${index}`}
                        className="object-contain h-full w-full"
                      />
                    </div>
                  </a>
                </div>
              ))}
        </div>
        <div className="lg:flex flex-row items-center justify-center py-3 bg-slate-100 hidden">
          <button
            className="h-12 w-12 text-white bg-black rounded-full mx-4"
            onClick={handlePrevClick}
          >
            &lt;
          </button>
          <button
            className="h-12 w-12 text-white bg-black rounded-full mx-4"
            onClick={handleNextClick}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  );
};

export default CarouselComponent;
