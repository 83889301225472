import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import CeritaKami from "./pages/cerita-kami/CeritaKami";
import ProdukUnggulan from "./pages/produk-unggulan/ProdukUnggulan";
import Artikel from "./pages/artikel/Artikel";
import DetailArtikel from "./pages/artikel/DetailArtikel";
import Reseller from "./pages/reseller/Reseller";
import Store from "./pages/store/Store";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="artikel" element={<Artikel />} />
        <Route path="artikel/:slug" element={<DetailArtikel />} />
        <Route path="cerita-kami" element={<CeritaKami />} />
        <Route path="produk-unggulan" element={<ProdukUnggulan />} />
        <Route path="reseller" element={<Reseller />} />
        <Route path="store" element={<Store />} />
      </Routes>
    </BrowserRouter>
  );
}
