const CarouselCeritaKami = ({ children, uniqueKey, name, img_link, key }) => {
  return (
    <>
      <div className="carousel-item">
        <div className="w-96 h-96 ">
          <img
            src={img_link}
            alt={key}
            width={400}
            height={400}
            className="bg-cover"
          />
        </div>
      </div>
    </>
  );
};

export default CarouselCeritaKami;
